// export const REALM_NAME = "brainiot-test";
// export const CLIENT_ID = "client_pub";

export const REALM_NAME = 'Brainiot';
export const CLIENT_ID = 'client_pub';

export const AUTH_ENDPOINT = 'https://ipt-services.polito.it/auth'
export const BACKEND_ENDPOINT = 'https://ipt-services.polito.it/brainpep'

export const REDIRECT_URI = 'https://ipt-services.polito.it/webgui/login';

//export const REDIRECT_URI = 'http://localhost:4000/login';
